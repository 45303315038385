const REQUIRED_VERIFICATION = 'required_verification';
const PENDING = 'pending';
const COMPLETE = 'complete';
const VERIFIED = 'verified';
const REJECTED = 'rejected';
export default {
    VERIFIED,
    PENDING,
    COMPLETE,
    REQUIRED_VERIFICATION,
    REJECTED
};
