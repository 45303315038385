<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-company-association flex-column jc-sb">
        <div class="add-company-association__container flex-column">
          <div class="add-company-association__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-company-association__title default-text">{{ isOnEditModal ? 'Edit associated company' : 'Add associated company' }}</h2>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-company-association__body w-100 flex-column">
            <CompanyAssociationForm @on-select="hanleSelect" v-if="!selectedCompany"/>
            <div class="flex-column" v-else>
              <div
                class="w-100 flex-column gap-0">
                <Typography variant="p">{{ selectedCompany.companyName }}</Typography>
                <Typography variant="custom" textSize="0.75rem" textWeight="400">
                  Company Number: {{ selectedCompany.companyNumber }}
                </Typography>
              </div>
              <el-checkbox v-model="agree">
                  <Typography variant="p" lineHeight="16px">
                    I am a person with significant control<br> at this company
                  </Typography>
              </el-checkbox>
            </div>
            <Button buttonText="save" :isActive="agree" class="w-100" v-if="selectedCompany"/>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';

import CompanyAssociationForm from '@/core/components/common/forms/company-association/CompanyAssociationForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    Button,
    CompanyAssociationForm,
    Typography,
  },

  emits: ['on-cancel', 'push-to-address-list', 'update-address-list'],

  props: ['show', 'userCertificates'],

  data() {
    return {

      saving: false,

      isFormReady: false,

      selectedCompany: null,

      agree: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  methods: {

    cancel() {
      this.$emit('on-cancel');
    },

    initialize() {
    },

    onSave() {
      this.saving = true;
    },

    hanleSelect(result) {
      this.selectedCompany = result;
    }
  },
});
</script>
<style lang="scss" scoped>
 .add-company-association {
    min-width: 350px;
    position: relative;
    max-height: 460px;
    height: 100%;

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }
 }
</style>
