<template>
    <InfoCardSlot
      class="company-item-card"
      :hasCustomHeader="true"
      :hasCustomFooter="companyData?.status"
      actionLabel="Verify Company Ownership">
      <template #custom-header v-if="companyData?.status">
        <Status :status="companyData?.status"/>
      </template>
      <template #custom-content>
        <div class="company-item-card__content flex-column jc-fs gap-1 h-auto w-100">
          <div class="company-item-card__wrapper flex-column w-100">
            <div class="flex-column gap-0">
              <Typography variant="h6">{{ companyData?.companyName }}</Typography>
              <Typography variant="custom" textSize=".75rem" textWeight="400">
                {{ companyData?.companyNumber }}
              </Typography>
            </div>
            <Status :status="iknowaVerifiedStatus.IKNOWA_VERIFIED" v-if="companyData?.isIknowaVerified"/>
          </div>
        </div>
      </template>
      <template #custom-footer v-if="companyData?.status">
        <Icon class="cursor-pointer" iconName="trash-icon.svg" iconHeight="18px" iconWidth="18px"/>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import Status from '@/core/components/common/status/Status.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import iknowaVerifiedStatus from '@/core/constants/status/iknowa-verified';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Icon,
    Status
  },

  props: {
    companyData: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      iknowaVerifiedStatus
    };
  },
});
</script>
<style lang="scss" scoped>
.company-item-card {

  &__content {
  }
  &__wrapper.grid {
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}
</style>
