<template>
  <div class="status-component flex-row ai-c gap-half">
    <Icon :iconName="iconName" customPath="icons/status-icons" iconHeight="18px" iconWidth="18px"/>
    <Typography
      whiteSpace="nowrap"
      variant="p"
      textWeight="600"
      :textColor="statusNameColor">
      {{ statusName }}
    </Typography>
    <Typography
      whiteSpace="nowrap"
      variant="p"
      textWeight="600"
      :textColor="spanTextColor">
      {{ spanText }}
    </Typography>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import statusType from '@/core/constants/status/type';

export default defineComponent({
  props: {
    status: {
      type: Object,
      required: true,
    },
  },

  components: {
    Icon,
    Typography
  },

  data() {
    return {
      statusType
    };
  },

  computed: {
    iconName() {
      const type = this.status?.type;
      const {
        COMPLETE,
        ALERT,
        VERIFIED
      } = this.statusType;

      switch (type) {
        case ALERT:
          return 'alert.svg';
        case COMPLETE:
          return 'complete.svg';
        case VERIFIED:
          return 'verified.svg';
        default:
          return 'alert.svg';
      }
    },
    statusNameColor() {
      const type = this.status?.type;
      const {
        COMPLETE,
        ALERT,
        VERIFIED
      } = this.statusType;

      switch (type) {
        case ALERT:
          return '#FA4B00';
        case COMPLETE:
          return '#40AE42';
        case VERIFIED:
          return '#FFA500';
        case 'default':
          return '#0C0F4A';
        default:
          return '#0C0F4A';
      }
    },
    spanTextColor() {
      return this.status?.spanTextColor || '#0C0F4A';
    },
    statusName() {
      return this.status?.statusName?.toUpperCase() || '';
    },
    spanText() {
      return this.status?.spanText?.toUpperCase() || '';
    },
  },

  created() {
  },
});
</script>

<style lang="scss" scoped>
</style>
