import { defineComponent } from 'vue';
import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import genericStatus from '@/core/constants/status/generic-status';
import statusType from '@/core/constants/status/type';
import CompanyItemCard from '@/modules/trades-passport-children/components/card-item/CompanyItemCard.vue';
import AddCompanyAssociationModal from '@/modules/trades-passport-children/components/modals/AddCompanyAssociationModal.vue';
export default defineComponent({
    name: 'TradesPassportOverview',
    components: {
        Typography,
        Icon,
        Button,
        CompanyItemCard,
        AddCompanyAssociationModal
    },
    data() {
        return {
            data: [
                {
                    status: {
                        status: genericStatus.PENDING,
                        type: statusType.ALERT,
                        statusName: 'PENDING',
                        spanText: 'COMPANY CONFIRMATION',
                    },
                    isIknowaVerified: true,
                    companyName: 'Iknowa Ltd',
                    companyNumber: '1445525'
                },
                {
                    status: {
                        status: genericStatus.VERIFIED,
                        type: statusType.VERIFIED,
                        statusName: 'VERIFIED',
                        spanText: 'ASSOCIATION'
                    },
                    isIknowaVerified: true,
                    companyName: 'Iknowa Ltd',
                    companyNumber: '1445525'
                },
                {
                    status: null,
                    isIknowaVerified: false,
                    companyName: 'HelloCrossman Ltd',
                    companyNumber: '1445525'
                },
            ],
            showCompanyAssociationModal: false,
        };
    },
    methods: {
        onToggleCompanyAssociationModal(event) {
            this.setCompanyAssociationModal(event);
        },
        setCompanyAssociationModal(event) {
            this.showCompanyAssociationModal = event;
        },
    },
});
