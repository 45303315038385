import { defineComponent } from 'vue';
import Typography from '@/core/components/ui/Typography.vue';
export default defineComponent({
    components: {
        Typography,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        hasCustomItem: Boolean,
    },
});
