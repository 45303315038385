<template>
    <div class="company-association-form flex-column">
      <el-form :model="form" class="default-form" label-position="top">
        <el-form-item label="Company Name / Number" prop="companyName">
          <el-input v-model="form.companyName" autocomplete="off"/>
        </el-form-item>
      </el-form>
      <SearchResultsCard v-if="searchResults.length" :hasCustomItem="true">
        <template #custom-item>
          <div
            class="company-association-form__item w-100 flex-column gap-0"
            v-for="(result, index) in searchResults" :key="index"
            @click="handleClick(result)">
            <Typography variant="p">{{ result.companyName }}</Typography>
            <Typography variant="custom" textSize="0.75rem" textWeight="400">
              Company Number: {{ result.companyNumber }}
            </Typography>
          </div>
        </template>
      </SearchResultsCard>
      <Button
        buttonText="find company"
        @click="handleSearch"
        :isActive="form.companyName !== ''"
        class="w-100"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import SearchResultsCard from '@/core/components/common/cards/SearchResultsCard.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    SearchResultsCard,
    Typography,
    Button
  },

  emits: ['on-select'],

  data() {
    return {
      form: {
        companyName: '',
      },
      sampleData: [
        {
          id: 1,
          companyName: 'Hellocrossman Ltd',
          companyNumber: '29846400'
        },
        {
          id: 2,
          companyName: 'Iknowa Ltd',
          companyNumber: '29846400'
        },
        {
          id: 3,
          companyName: 'Cross io inc.',
          companyNumber: '29846400'
        },
        {
          id: 4,
          companyName: 'jakuzi inc.',
          companyNumber: '29846400'
        },
        {
          id: 5,
          companyName: 'haya hello inc.',
          companyNumber: '29846400'
        },
      ],
      searchResults: [],
    };
  },

  methods: {
    handleSearch() {
      const searchTerm = this.form.companyName.toLowerCase();
      this.searchResults = searchTerm
        ? this.sampleData.filter((item) => item.companyName.toLowerCase().includes(searchTerm)) : [];
    },

    handleClick(result) {
      this.$emit('on-select', result);
    }
  },
});
</script>
<style lang="scss" scoped>
.company-association-form{
  &__item {
    cursor: pointer;
    padding: .3rem 1rem;
    border-radius: 12px;
    box-sizing: border-box;
    &:hover {
      background: rgba(12, 15, 74, 0.1);
    }
  }
}
.el-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
    .el-form-item {
        margin: 0;

       :deep(.el-form-item__label) {
           font-family: Mulish;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
       }

       :deep(.el-input) {
          width: 100%;
          border-radius: 100px;
          height: 42px;
          background: rgba(12, 15, 74, 0.05);

          .el-input__inner {
            border-radius: 100px;
            outline:none;
            border: none;
            padding: 20px;
            background: transparent;
          }
       }

      :deep(.el-input__suffix) {
        right:20px;
        top: 5px;
        color:#111;
        display: inline-block !important;
      }
    }
}
</style>
